import { useMemo, useState } from "react";
import { IconSearch, IconChevronRight } from "@tabler/icons-react";
import { Stack, Skeleton } from "@mantine/core";
import { usePartners } from "@/store/selectors";
import { NavLink } from "@/components/ui/NavLink";
import { PartnerLogo } from "@/components/PartnerLogo";
import { TextInput } from "@/components/ui/TextInput";

export const PartnerSelector = () => {
  const [partnerSearchText, setPartnerSearchText] = useState("");
  const partners = usePartners();

  const sortedPartners = useMemo(() => {
    if (!partners) return null;

    return [...(partners || [])]
      ?.filter((partner: any) =>
        partner.title.toLowerCase().includes(partnerSearchText.toLowerCase()),
      )
      ?.sort((a: any, b: any) => a.title.localeCompare(b.title));
  }, [partners, partnerSearchText]);

  return (
    <>
      <TextInput
        id="partnerSearchInput"
        value={partnerSearchText}
        onChange={(e) => setPartnerSearchText(e.target.value)}
        placeholder="Search for your school"
        size="md"
        radius="xl"
        leftSection={<IconSearch size={18} />}
      />
      <Stack w="100%" gap={0}>
        {(sortedPartners || Array.from({ length: 10 })).map(
          (partner: any, index: number) => {
            if (!sortedPartners?.length) {
              return <Skeleton key={index} w="100%" h={50} />;
            }
            return (
              <NavLink
                className="hoverable"
                key={partner.id || index}
                size="md"
                to={`/login/${partner.slug}`}
                CustomIcon={<PartnerLogo partnerId={partner.id} />}
                rightSection={
                  <IconChevronRight
                    size="0.8rem"
                    stroke={1.5}
                    className="mantine-rotate-rtl"
                  />
                }
              >
                {partner.title}
              </NavLink>
            );
          },
        )}
      </Stack>
    </>
  );
};
