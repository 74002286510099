import { Link } from "@/components/ui/Link";
import { config } from "@/config";
import { Text } from "@mantine/core";

export const TermsAndConditions = () => {
  return (
    <Text size="sm" c="dimmed" opacity={0.8}>
      By using this app, you agree to our <br />
      <Link to={config.termsUrl} target="_blank">
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link to={config.privacyUrl} target="_blank">
        Privacy Policy
      </Link>
    </Text>
  );
};
