import { useEffect, useMemo, useState } from "react";
import { useStore } from "./index";
import { Advizer, IAdvizerModel } from "../models/Advizer";
import {
  IAdvizeApiDataKeys,
  IAdvizerSearchFilterKeys,
} from "../types/StoreTypes";
import { searchAdvizers } from "../lib/advizerSearch";
import { User } from "../models/User";
import { IPartner, IQuestion } from "@/types/ApiTypes";

/* Api Data */
export const useSetApiData = () => {
  return useStore((state) => state.advizeApiData.setApiData);
};

export const useApiData = (key: IAdvizeApiDataKeys) => {
  const advizeApiData = useStore((state) => state.advizeApiData[key]);
  return advizeApiData || null;
};

export const useIsApiDown = () => {
  const advizers = useStore((state) => state.advizeApiData.advizers);
  return advizers && !advizers?.length;
};

export const useAllQuestions = () => {
  const questions = useStore((state) => state.advizeApiData.questions);
  return questions;
};

export const useQuestions = () => {
  const questions = useAllQuestions();
  const activePartner = useActiveUserPartner();
  return (
    questions
      ?.filter(
        (question: IQuestion) =>
          question.isStandard ||
          question.partners?.find((p: IPartner) => p.id === activePartner?.id),
      )
      .sort((a: IQuestion, b: IQuestion) => {
        const aRank = (a.number || 0) + (a.isStandard ? 0 : -10000);
        const bRank = (b.number || 0) + (b.isStandard ? 0 : -10000);
        // sort not isStandard to the top, then by number
        return aRank - bRank;
      }) || []
  );
};

export const useCurrentPartnerHasSpecialQuestions = () => {
  const questions = useQuestions();
  return questions?.some((question: any) => !question.isStandard);
};

export const useActiveQuestion = () => {
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const questions = useQuestions();
  return questions.find((question: any) => question.id === activeQuestionId);
};

/* Advizer Search */

let timeout: any;
let isSearchingTimeout: any;

export const useIsSearchingAdvizers = () => {
  const isSearchingAdvizers = useStore(
    (state) => state.advizerSearch.isSearchingAdvizers,
  );
  const setIsSearchingAdvizers = useStore(
    (state) => state.advizerSearch.setIsSearchingAdvizers,
  );
  return {
    isSearchingAdvizers,
    setIsSearchingAdvizers,
  };
};

// TODO make a map of visible advizers and return null if not visible, only look for the ID with a hook in each AdvizerVideoCard, return null if not
export const useFilteredAdvizers = (skipEffect = false) => {
  const { advizers, isAdvizersLoading } = useAdvizers();
  const [filteredAdvizers, setFilteredAdvizers] = useState<
    IAdvizerModel[] | null
  >(null);
  const { isSearchingAdvizers, setIsSearchingAdvizers } =
    useIsSearchingAdvizers();
  const { searchText } = useAdvizerSearchText();
  const { subjects, industries, jobFunctions, traits, companies } =
    useSelectedAdvizerSearchFilters();

  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );

  const activeFilterPartnerId = useStore(
    (state) => state.advizerSearch.activeFilterPartnerId,
  );

  // const searchText = "star";
  // const subjects = {};

  useEffect(() => {
    if (!advizers || skipEffect) return;

    setIsSearchingAdvizers(true);

    timeout = setTimeout(() => {
      const filtered = searchAdvizers({
        advizers: advizers || [],
        searchText,
        selectedSubjects: subjects,
        selectedIndustries: industries,
        selectedJobFunctions: jobFunctions,
        selectedTraits: traits,
        selectedCompanies: companies,
        partnerId: activeFilterPartnerId,
        activeQuestionId,
      });
      setFilteredAdvizers(filtered);

      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "instant" });
      }, 100);

      isSearchingTimeout = setTimeout(() => {
        setIsSearchingAdvizers(false);
      }, 300);
    }, 300);

    return () => {
      clearTimeout(timeout);
      clearTimeout(isSearchingTimeout);
    };
  }, [
    searchText,
    subjects,
    industries,
    advizers,
    jobFunctions,
    traits,
    companies,
    skipEffect,
    activeFilterPartnerId,
    activeQuestionId,
    setIsSearchingAdvizers,
  ]);

  return {
    advizers: filteredAdvizers || advizers,
    isAdvizersLoading,
    isSearchingAdvizers,
  };
};

export const useFilteredAdvizersCount = () => {
  const filteredAdvizers = useFilteredAdvizers();
  return filteredAdvizers?.advizers?.length || 0;
};

export const useSelectedSubjects = () => {
  const subjects = useStore((state) => state.advizerSearch.subjects);
  return subjects;
};

export const useSelectedAdvizerSearchFilters = () => {
  const subjects = useStore((state) => state.advizerSearch.subjects);
  const industries = useStore((state) => state.advizerSearch.industries);
  const jobFunctions = useStore((state) => state.advizerSearch.jobFunctions);
  const traits = useStore((state) => state.advizerSearch.traits);
  const companies = useStore((state) => state.advizerSearch.companies);
  const searchText = useStore((state) => state.advizerSearch.searchText);
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const activeFilterPartnerId = useStore(
    (state) => state.advizerSearch.activeFilterPartnerId,
  );

  return {
    subjects,
    industries,
    jobFunctions,
    traits,
    companies,
    searchText,
    activeQuestionId,
    activeFilterPartnerId,
  };
};

export const useCheckIsFilterOptionSelected = ({
  filterKey,
  resourceId,
}: {
  filterKey: IAdvizerSearchFilterKeys;
  resourceId: string;
}) => {
  return useStore((state) => !!state.advizerSearch?.[filterKey]?.[resourceId]);
};

export const useToggleAdvizerSearchFilterOption = () => {
  return useStore((state) => state.advizerSearch.toggleFilterOption);
};

export const useIsFilterOptionSelected = ({
  filterKey,
  resourceId,
}: {
  filterKey: IAdvizerSearchFilterKeys;
  resourceId: string;
}) => {
  return useStore((state) => state.advizerSearch?.[filterKey]?.[resourceId]);
  // if (!filterKey || !resourceId) return false;

  // return checkIsFilterOptionSelected(filterKey, resourceId);
};

export const useAdvizerSearchText = () => {
  const searchText = useStore((state) => state.advizerSearch.searchText);
  const setSearchText = useStore((state) => state.advizerSearch.setSearchText);
  return {
    searchText,
    setSearchText,
  };
};

export const useIsAdvizersFiltered = () => {
  const { searchText } = useAdvizerSearchText();
  const { subjects, industries, jobFunctions, traits, companies } =
    useSelectedAdvizerSearchFilters();
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );

  return !(
    searchText.length === 0 &&
    Object.values(subjects).length === 0 &&
    Object.values(industries).length === 0 &&
    Object.values(jobFunctions).length === 0 &&
    Object.values(traits).length === 0 &&
    Object.values(companies).length === 0 &&
    !activeQuestionId
  );
};

export const useSubjectFilterOptions = () => {
  const subjects = useStore((state) => state.advizeApiData.subjects);
  return {
    subjects: subjects
      ? [...subjects].sort((a, b) => a.title.localeCompare(b.title))
      : [],
    isSubjectsLoading: !subjects,
  };
};

export const useJobFunctionFilterOptions = () => {
  const jobFunctions = useStore((state) => state.advizeApiData.jobFunctions);
  return {
    jobFunctions: jobFunctions
      ? [...jobFunctions].sort((a, b) => a.title.localeCompare(b.title))
      : [],
    isJobFunctionsLoading: !jobFunctions,
  };
};

export const useIndustryFilterOptions = () => {
  const industries = useStore((state) => state.advizeApiData.industries);
  return {
    industries: industries
      ? [...industries].sort((a, b) => a.title.localeCompare(b.title))
      : [],
    isIndustriesLoading: !industries,
  };
};

export const useCompanyFilterOptions = () => {
  const { advizers } = useAdvizers();
  const companies = useMemo(() => {
    return [...new Set((advizers || []).map((advizer) => advizer.company))]
      .filter((company) => !!company)
      .sort((a, b) => a?.localeCompare(b));
  }, [advizers]);

  return {
    companies: companies,
    isCompaniesLoading: false,
  };
};

export const useTraitFilterOptions = () => {
  const traits = useStore((state) => state.advizeApiData.traits);
  return {
    traits: traits
      ? [...traits].sort((a, b) => a.title.localeCompare(b.title))
      : [],
    isTraitsLoading: !traits,
  };
};

export const useArrayOfSelectedAdvizerSearchFilters = () => {
  const { traits: traitOptions } = useTraitFilterOptions();
  const { companies: companyOptions } = useCompanyFilterOptions();
  const { industries: industryOptions } = useIndustryFilterOptions();
  const { jobFunctions: jobFunctionOptions } = useJobFunctionFilterOptions();
  const { subjects: subjectOptions } = useSubjectFilterOptions();

  function makeOptionLookupMap(options: any[]) {
    return options.reduce(
      (acc, item) => {
        acc[item?.id || item] = item;
        return acc;
      },
      {} as Record<string, any>,
    );
  }

  const optionLookupMap = {
    traits: makeOptionLookupMap(traitOptions),
    companies: makeOptionLookupMap(companyOptions),
    industries: makeOptionLookupMap(industryOptions),
    jobFunctions: makeOptionLookupMap(jobFunctionOptions),
    subjects: makeOptionLookupMap(subjectOptions),
  };

  const { companies, industries, jobFunctions, subjects, traits } =
    useSelectedAdvizerSearchFilters();

  function makeArrayOfSelectedFilters(
    filterMap: Record<string, boolean>,
    type: "companies" | "industries" | "jobFunctions" | "subjects" | "traits",
  ) {
    return Object.keys(filterMap)
      .filter((key) => filterMap[key])
      .map((key) => {
        const item = optionLookupMap[type][key];
        return {
          id: key,
          type,
          title: item?.title || item,
        };
      });
  }

  const selectedFilters = {
    companies: makeArrayOfSelectedFilters(companies, "companies"),
    industries: makeArrayOfSelectedFilters(industries, "industries"),
    jobFunctions: makeArrayOfSelectedFilters(jobFunctions, "jobFunctions"),
    subjects: makeArrayOfSelectedFilters(subjects, "subjects"),
    traits: makeArrayOfSelectedFilters(traits, "traits"),
  };

  return Object.values(selectedFilters).flat();
};

export const useShowPartnerToggle = () => {
  const partner = useActiveUserPartner();
  const { advizers } = useAdvizers();

  if (!partner) return false;

  const totalAdvizersWithPartner = (advizers || [])?.filter((advizer) =>
    advizer?.partners?.some((p: any) => p?.id === partner?.id),
  )?.length;

  return !!partner && totalAdvizersWithPartner > 15;
};

/* Advizers */

export const useApiAdvizers = () => {
  return useStore((state) => state.advizeApiData.advizers);
};

export const useAdvizers = () => {
  const apiAdvizers = useApiAdvizers();
  const advizers = useMemo(() => {
    return apiAdvizers?.map((advizer) => new Advizer(advizer));
  }, [apiAdvizers]);

  return {
    advizers: advizers,
    isAdvizersLoading: !advizers?.length,
  };
};

export const useAdvizerMap = () => {
  const { advizers } = useAdvizers();
  const advizerMap = useMemo(() => {
    return (advizers || []).reduce(
      (acc, advizer) => {
        acc[advizer.id] = advizer;
        return acc;
      },
      {} as Record<string, IAdvizerModel>,
    );
  }, [advizers]);
  return advizerMap;
};

export const useAdvizer = (advizerId: string) => {
  const advizerMap = useAdvizerMap();
  const advizerRaw = advizerMap[advizerId];

  if (!advizerRaw) {
    return null;
  }

  return new Advizer(advizerRaw);
};

/* Ui Slice */

export const useUiSlice = () => {
  const store = useStore();
  return store;
};

/* Drawer */

export const useDrawer = (drawerId?: string) => {
  const activeDrawerId = useStore((state) => state.drawers.activeDrawerId);
  const setActiveDrawer = useStore((state) => state.drawers.setActiveDrawer);

  return {
    activeDrawerId,
    isOpen: activeDrawerId === drawerId,
    openDrawer: () => setActiveDrawer(drawerId || null),
    closeDrawer: () => setActiveDrawer(null),
    closeActiveDrawer: () => setActiveDrawer(null),
  };
};

export const useCloseActiveDrawer = () => {
  const { closeActiveDrawer } = useDrawer();
  return closeActiveDrawer;
};

/* Dialogs */
type IDialogId = "welcome" | "cookieConsent";
export const useDialog = (dialogId?: IDialogId) => {
  const activeDialogId = useStore((state) => state.dialog.activeDialogId);
  const setActiveDialog = useStore((state) => state.dialog.setActiveDialog);

  return {
    activeDialogId,
    isOpen: activeDialogId === dialogId,
    openDialog: () => setActiveDialog(dialogId || null),
    closeDialog: () => setActiveDialog(null),
    closeActiveDialog: () => setActiveDialog(null),
  };
};

/* Videos */

export const useIsVideoPlaying = (videoId: string) => {
  const activeVideoId = useStore((state) => state.videos.activeVideoId);
  return activeVideoId === videoId;
};

/* User */

export const useActiveUser = () => {
  const user = useStore((state) => state.user.activeUser);
  return user ? new User(user) : null;
};

export const useIsLoggedIn = () => {
  const user = useActiveUser();
  return !!user;
};

export const useIsRestrictedApp = () => {
  const isLoggedIn = useIsLoggedIn();
  const numVideosPlayed = useStore((state) => state.user.numVideosPlayed);
  return !isLoggedIn && numVideosPlayed > 2;
};

/* Partners */

export const usePartners = () => {
  const partners = useStore((state) => state.advizeApiData.partners);
  return partners;
};

export const usePartner = (partnerId?: string) => {
  const partners = usePartners();
  if (!partnerId) return null;
  return partners?.find((partner) => partner.id === partnerId);
};

export const usePartnerBySlug = (partnerSlug?: string) => {
  const partners = usePartners();
  const activePartner = useStore((state) => state.partner.activePartner);
  if (!partnerSlug) return null;
  if (activePartner?.slug === partnerSlug) return activePartner;
  return partners?.find((partner: any) => partner.slug === partnerSlug);
};

export const useActiveUserPartner = () => {
  const activePartner = useStore((state) => state.partner.activePartner);

  return activePartner;
};
