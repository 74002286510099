import { useCallback, useEffect } from "react";
import { shuffleArray } from "@/lib/helpers";
import { AdvizeApi } from "@/api/api";
import { useIsApiDown, useSetApiData } from "@/store/selectors";
import { Advizer } from "@/models/Advizer";
import { IAdvizeApiDataKeys } from "@/types/StoreTypes";

export const Api = () => {
  const setApiData = useSetApiData();
  const isApiDown = useIsApiDown();

  const init = useCallback(async () => {
    const fetchAndSetData = async (
      apiCall: (config?: { noCache?: boolean }) => Promise<any>,
      key: IAdvizeApiDataKeys,
      transform?: (data: any) => any,
      shuffle?: boolean,
    ) => {
      let items: any[] = [];
      try {
        const response = await apiCall();
        items =
          response?.data?.items?.items ||
          response?.data?.items ||
          response?.data ||
          [];

        if (!items.length) {
          throw new Error("No items found");
        }
      } catch (e) {
        console.error(e);
        if (!items.length) {
          const response2 = await apiCall({ noCache: true });
          items =
            response2?.data?.items?.items ||
            response2?.data?.items ||
            response2?.data ||
            [];
        }
      }

      if (shuffle) {
        items = shuffleArray(items);
      }
      setApiData({ key, data: transform ? items.map(transform) : items });
    };

    await Promise.all([
      fetchAndSetData(
        (config) =>
          AdvizeApi.queryAdvizersWithEverything({
            take: 1000,
            skip: 0,
            ...config,
          }),
        "advizers",
        (a: any) => new Advizer(a),
        true,
      ),
      fetchAndSetData((config) => AdvizeApi.querySubjects(config), "subjects"),
      fetchAndSetData(
        (config) => AdvizeApi.queryJobFunctions(config),
        "jobFunctions",
      ),
      fetchAndSetData(
        (config) => AdvizeApi.queryIndustries(config),
        "industries",
      ),
      fetchAndSetData((config) => AdvizeApi.queryTraits(config), "traits"),
      fetchAndSetData(
        (config) => AdvizeApi.queryQuestions(config),
        "questions",
      ),
      fetchAndSetData((config) => AdvizeApi.queryPartners(config), "partners"),
    ]);
  }, [setApiData]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isApiDown) {
      console.error("API is down");
    }
  }, [isApiDown]);

  return null;
};
