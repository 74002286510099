import { forwardRef } from "react";
import {
  Select as MantineSelect,
  SelectProps as MantineSelectProps,
} from "@mantine/core";
import { useStyles } from "@/styles/useStyles";
import classes from "@/styles/TextInput.module.css";

export interface SelectProps extends MantineSelectProps {
  bold?: boolean;
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(
  (props, ref) => {
    const { className, styles, bold, label, placeholder, ...rest } = props;
    const { theme } = useStyles();
    const hasValue = !!props.value;
    return (
      <MantineSelect
        // role="combobox"
        aria-label={String(label || placeholder)} // Add aria-label
        className={`${className} ${classes.textInput}`}
        classNames={{
          input: classes.textInput, // Apply the custom class
        }}
        withCheckIcon={false}
        label={label}
        placeholder={placeholder}
        styles={{
          input: {
            "&::placeholder": {
              color: theme.colors.red[9], // Change the color here
            },
            color:
              bold && !hasValue
                ? theme.colors.primary[6]
                : theme.colors.text[7],
            fontWeight: bold && !hasValue ? 600 : 500,
            border:
              bold && !hasValue
                ? `1px solid ${theme.colors.primary[2]}`
                : undefined,
          },
          option: {
            color: theme.colors.text[8],
            fontWeight: 400,
          },

          ...styles,
        }}
        {...rest}
        ref={ref}
      />
    );
  },
);

Select.displayName = "Select";
