import "@mantine/core/styles.css";
import {
  AppShell,
  Group,
  Avatar,
  Indicator,
  Burger,
  Skeleton,
  Stack,
} from "@mantine/core";
import logo from "@/assets/advize-logo.webp";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import {
  MainMenuDrawer,
  drawerId as mainMenuDrawerId,
} from "@/components/drawers/MainMenuDrawer";
import {
  UserDrawer,
  drawerId as userDrawerId,
} from "@/components/drawers/UserDrawer";
import {
  useDrawer,
  useIsLoggedIn,
  useActiveUserPartner,
} from "@/store/selectors";
import { useStore } from "@/hooks";
import { NAV_ITEMS } from "@/configs/navItems";
import { Link } from "@/components/ui/Link";
import { NavLink } from "@/components/ui/NavLink";
import { PartnerLogo } from "@/components/PartnerLogo";
import { Button } from "@/components/ui/Button";
import { Analytics } from "@/services/Analytics";
import { DialogContainer } from "@/components/dialogs/DialogContainer";
import { Confetti } from "@/components/Confetti";
import { Image } from "@/components/ui/Image";
import { Text } from "@/components/ui/Text";

export function PageLayout({ children }: { children: React.ReactNode }) {
  const { theme, ...styles } = useStyles();
  const { pathname } = useLocation();
  const {
    openDrawer: openMainMenuDrawer,
    isOpen: isMainMenuDrawerOpen,
    closeDrawer,
  } = useDrawer(mainMenuDrawerId);
  const { openDrawer: openUserDrawer } = useDrawer(userDrawerId);
  const partner = useActiveUserPartner();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isLoggedIn = useIsLoggedIn();
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const isAppLoading = useStore((state) => state.isAppLoading);

  const scrollOverflow = useMemo(() => {
    return pathname.includes("login");
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  const renderBurgerButton = useCallback(() => {
    return (
      <Burger
        opened={isMainMenuDrawerOpen}
        onClick={isMainMenuDrawerOpen ? closeDrawer : openMainMenuDrawer}
        color="border.3"
        size={isLaptopOrBigger ? "md" : "sm"}
        lineSize={1}
        aria-label="Open main menu"
      />
    );
  }, [isMainMenuDrawerOpen, closeDrawer, openMainMenuDrawer, isLaptopOrBigger]);

  const renderMainTitle = useCallback(() => {
    if (isLaptopOrBigger) {
      return (
        <Link
          to={"/"}
          onClick={() => {
            if (pathname == "/") {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}
        >
          {partner ? (
            <Group align="center" justify="center" pt={4}>
              <PartnerLogo width={34} partnerId={partner?.id} noLink />
              <Text titleStyle={isLaptopOrBigger ? "h4" : "h5"} c="white">
                {partner.title}
              </Text>
            </Group>
          ) : (
            <Group gap={2}>
              <Image
                src={logo}
                w={isLaptopOrBigger ? 34 : 26}
                mb={2}
                alt="Advize logo"
              />
              <Text titleStyle={isLaptopOrBigger ? "h2" : "h3"} c="white">
                advize
              </Text>
            </Group>
          )}
        </Link>
      );
    }
    return (
      <Link
        to={"/"}
        onClick={() => {
          if (pathname == "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
      >
        {partner ? (
          <Group align="center" justify="center" pt={4}>
            {/* <PartnerLogo width={24} /> */}
            <Text titleStyle="h5" c="white">
              {partner.title}
            </Text>
          </Group>
        ) : (
          <Group gap={2}>
            <Image src={logo} w={26} mb={2} alt="Advize logo" />
            <Text titleStyle="h3" c="white">
              advize
            </Text>
          </Group>
        )}
      </Link>
    );
  }, [partner, isLaptopOrBigger, pathname]);

  const renderAvatar = useCallback(() => {
    if (!isLoggedIn) {
      return (
        <Group>
          <Button
            variant="outline"
            onClick={(event) => {
              openUserDrawer();
              Analytics.click({ action: "Go to sign in from header", event });
            }}
            size={isLaptopOrBigger ? "sm" : "xs"}
            // bg="secondary.9"
            color="secondary.5"
            radius="xl"
            to="/login"
          >
            Sign in
          </Button>
        </Group>
      );
    }
    return (
      <Group>
        <Indicator
          offset={isLaptopOrBigger ? 8 : 6}
          position="top-end"
          color="red.5"
          size={isLaptopOrBigger ? 8 : 6}
          inline
        >
          <Button
            variant="transparent"
            p={0}
            onClick={() => openUserDrawer()}
            opacity={0.8}
            size={isLaptopOrBigger ? "xl" : "md"}
          >
            <Avatar
              color="secondary"
              radius="xl"
              size={isLaptopOrBigger ? 44 : 32}
            />
          </Button>
        </Indicator>
      </Group>
    );
  }, [isLaptopOrBigger, isLoggedIn, openUserDrawer]);

  const renderHeaderContent = useCallback(() => {
    if (isAppLoading) {
      return (
        <Group>
          <Skeleton
            w="100%"
            h={styles.dimensions.headerHeight}
            radius={0}
            opacity={1}
          />
        </Group>
      );
    }

    if (isLaptopOrBigger) {
      return (
        <Group h="100%" px="md" justify="space-between">
          <Group>
            {renderBurgerButton()}
            {renderMainTitle()}
            <Group pl="xl" gap="sm">
              {NAV_ITEMS.filter((navItem) => !!navItem.showOnDesktopNavbar).map(
                (navItem, index) => {
                  return (
                    <NavLink
                      key={index}
                      to={navItem.href}
                      textProps={{ c: theme.colors.text[2] }}
                      linkProps={{ target: navItem.target }}
                      active={false}
                      onClick={(event) => {
                        Analytics.click({
                          action: `Nav Menu > ${navItem.label}`,
                          event,
                        });
                      }}
                    >
                      {navItem.label}
                    </NavLink>
                  );
                },
              )}
            </Group>
          </Group>

          <Group gap="xl">{renderAvatar()}</Group>
        </Group>
      );
    }
    return (
      <Group h="100%" px="md" justify="space-between">
        {renderBurgerButton()}
        {renderMainTitle()}
        {renderAvatar()}
      </Group>
    );
  }, [
    isAppLoading,
    isLaptopOrBigger,
    renderAvatar,
    renderBurgerButton,
    renderMainTitle,
    styles.dimensions.headerHeight,
    theme.colors.text,
  ]);

  return (
    <AppShell
      header={{
        height: styles.dimensions.headerHeight,
        collapsed: collapseHeader,
        offset: false,
      }}
      padding="md"
      style={{
        position: "relative",
      }}
      styles={{
        root: {
          width: "100%",
        },
      }}
    >
      <AppShell.Header bg="primary.9">{renderHeaderContent()}</AppShell.Header>

      <AppShell.Main
        bg={theme.colors.background[0]}
        p={0}
        h="100vh"
        style={{ overflow: scrollOverflow ? "auto" : "hidden" }}
      >
        {isAppLoading ? (
          <Stack flex={1} h="100vh" w="100%" bg="slate.0" />
        ) : (
          children
        )}
        <Confetti />
      </AppShell.Main>

      <MainMenuDrawer />
      <UserDrawer />
      <DialogContainer />
    </AppShell>
  );
}
