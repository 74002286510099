import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "@/store";

export const UiUpdates = () => {
  const { pathname } = useLocation();

  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader,
  );

  useEffect(() => {
    setCollapseHeader(false);
  }, [pathname, setCollapseHeader]);

  return null;
};
