import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "@/firebase/firebase";
import { useStore } from "@/store";
import mixpanel from "mixpanel-browser";
import { useActiveUser, useDialog } from "@/store/selectors";
import { RealtimeDatabase } from "@/services/RealtimeDatabase";

export function Auth() {
  const setUser = useStore((state) => state.user.setUser);
  const { closeActiveDialog } = useDialog();
  const updateActiveUser = useStore((state) => state.user.updateActiveUser);
  const activeUser = useActiveUser();
  const { activeDialogId, openDialog: openCookieDialog } =
    useDialog("cookieConsent");
  const [didSubscribeToUser, setDidSubscribeToUser] = useState(false);
  const clearNumVideosPlayed = useStore(
    (state) => state.user.clearNumVideosPlayed,
  );

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setDidSubscribeToUser(false);

      console.log("onAuthStateChanged", { user });

      if (user) {
        setUser(user);
        clearNumVideosPlayed();
        try {
          mixpanel.identify(user.uid);
          mixpanel.people.set({
            $name: user.displayName || user.email?.split("@")[0] || "Anonymous",
            $email: user.email,
            isAnonymous: user.isAnonymous,
          });
        } catch (error) {
          console.error("Error identifying and setting Mixpanel user", error);
        }
      } else {
        setUser(null);
        closeActiveDialog();
      }
    });
  }, []);

  useEffect(() => {
    console.log({ activeUser, didSubscribeToUser });
    if (!activeUser || didSubscribeToUser) return;

    setDidSubscribeToUser(true);

    RealtimeDatabase.subscribeToUser(activeUser.id, (userData: any) => {
      console.log({ userData });
      updateActiveUser({
        ...userData,
        acceptedCookies: userData?.acceptedCookies || false,
        rejectedCookies: userData?.rejectedCookies || false,
      });

      if (
        !userData?.acceptedCookies &&
        !userData?.rejectedCookies &&
        !activeDialogId
      ) {
        setTimeout(() => {
          openCookieDialog();
        }, 3000);
      }

      if (!userData?.email && activeUser?.email) {
        RealtimeDatabase.updateCurrentUser({
          email: activeUser.email,
        });
      }

      if (userData?.rejectedCookies) {
        console.log("OPTING OUT OF MIXPANEL");
        mixpanel.opt_out_tracking();
      }
    });
  }, [
    activeUser,
    didSubscribeToUser,
    openCookieDialog,
    updateActiveUser,
    activeDialogId,
  ]);

  return null;
}
