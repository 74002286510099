import { IconX } from "@tabler/icons-react";
import { ResourceConfigs } from "@/configs/resourceConfigs";
import {
  useIsFilterOptionSelected,
  useToggleAdvizerSearchFilterOption,
} from "@/store/selectors";
import { Badge, BadgeProps } from "@/components/ui/Badge";
import { Analytics } from "@/services/Analytics";
import { useCallback } from "react";

export type IAdvizerTagTypes = keyof typeof ResourceConfigs;

export interface IAdvizerTag {
  id: string;
  title: string;
  type: IAdvizerTagTypes;
}

export interface AdvizerTagProps extends Omit<BadgeProps, "ariaLabel"> {
  tag: IAdvizerTag;
}

export const AdvizerTag = (props: AdvizerTagProps) => {
  const { tag, ...rest } = props;
  const resourceConfig = ResourceConfigs[tag.type];
  const isSelectedFilterOption = useIsFilterOptionSelected({
    filterKey: resourceConfig.filtersKey,
    resourceId: tag.id,
  });

  const toggleFilterOption = useToggleAdvizerSearchFilterOption();

  const handleToggleFilterOption = useCallback(() => {
    toggleFilterOption(resourceConfig.filtersKey, tag.id);

    if (!isSelectedFilterOption) {
      Analytics.filterAdvizers({
        filterType: resourceConfig.filtersKey,
        filterValue: tag.title,
        resourceId: tag.id,
        context: "Advizer Tag",
      });
    }
  }, [
    isSelectedFilterOption,
    resourceConfig.filtersKey,
    tag.id,
    tag.title,
    toggleFilterOption,
  ]);

  return (
    <Badge
      ariaLabel={tag.title}
      variant={isSelectedFilterOption ? "filled" : "light"}
      color={`${resourceConfig.color || "primary"}.7`}
      style={{ display: "flex", alignItems: "center" }}
      leftSection={
        isSelectedFilterOption ? (
          <IconX size={16} />
        ) : (
          <resourceConfig.Icon size={16} />
        )
      }
      onClick={handleToggleFilterOption}
      {...rest}
    >
      {tag.title}
    </Badge>
  );
};
