import { forwardRef } from "react";
import {
  Badge as MantineBadge,
  BadgeProps as MantineBadgeProps,
  Box,
} from "@mantine/core";
import { Accessibility } from "@/services/Accessibility";

export interface BadgeProps extends MantineBadgeProps {
  onClick?: () => void;
  ariaLabel: string;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  const { className, ariaLabel, onClick, ...rest } = props;

  return (
    <Box
      role="button"
      aria-label={ariaLabel}
      onClick={onClick}
      p={0}
      onKeyDown={(e) => Accessibility.handleOnKeyDown({ e, onClick })}
      tabIndex={Accessibility.handleTabIndex({ onClick })}
    >
      <MantineBadge
        className={`clickable ${className}`}
        tt="none"
        {...rest}
        ref={ref}
      />
    </Box>
  );
});

Badge.displayName = "Badge";
