import { Drawer } from "@/components/ui/Drawer";
import { AdvizerSearchFilters } from "@/components/AdvizerSearchFilters";

export const drawerId = "advizerSearchDrawer";

export const AdvizerSearchDrawer = () => {
  return (
    <Drawer
      id={drawerId}
      withDoneButton
      position="right"
      size="md"
      title="Search Advizers"
      onClose={() => {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }}
    >
      <AdvizerSearchFilters />
    </Drawer>
  );
};
