import { onValue, ref, update, off } from "firebase/database";
import { db } from "@/firebase/firebase";
import { useStore } from "@/store";
import { User } from "@/models/User";

class RealtimeDatabaseService {
  get db() {
    return db;
  }

  updateUser(userId: string, data: any) {
    const userRef = ref(this.db, `users/${userId}`);
    console.log("Updating user", { userId, data });
    update(userRef, data);
  }

  updateCurrentUser(data: any) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    console.log("Updating current user", { activeUser, data });

    this.updateUser(activeUser.id, data);

    // const userRef = ref(this.db, `users/${activeUser.id}`);
    // update(userRef, data);
  }

  updateCurrentUserUsageData(data: any) {
    const activeUser = new User(useStore.getState().user?.activeUser);
    if (!activeUser) return;

    this.updateUser(activeUser.id, data);
  }

  subscribeToUser(userId: string, callback: (user: User) => void) {
    this.unsubscribeFromUser(userId);
    console.log("Subscribing to user", { userId });
    const userRef = ref(this.db, `users/${userId}`);
    off(userRef, "value");

    onValue(
      userRef,
      (snapshot) => {
        const userData = snapshot.val();
        console.log("User data", { userData });
        callback(userData);
      },
      (error) => {
        console.error("Error subscribing to user", error);
      },
    );
  }

  unsubscribeFromUser(userId: string) {
    const userRef = ref(this.db, `users/${userId}`);
    off(userRef, "value");
  }
}

export const RealtimeDatabase = new RealtimeDatabaseService();
