import { forwardRef } from "react";
import {
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
} from "@mantine/core";

export interface ActionIconProps extends MantineActionIconProps {
  onClick?: () => void;
  ariaLabel: string;
}

export const ActionIcon = forwardRef<HTMLDivElement, ActionIconProps>(
  (props, _ref) => {
    const { className, ariaLabel, ...rest } = props;
    return (
      <MantineActionIcon
        className={`clickable ${className}`}
        aria-label={ariaLabel}
        role="button"
        {...rest}
      />
    );
  }
);

ActionIcon.displayName = "ActionIcon";
