import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { useCloseActiveDrawer } from "@/store/selectors";

export interface LinkProps extends RouterLinkProps {
  disabled?: boolean;
  radius?: string | number;
  ariaLabel?: string;
}

export const Link = (props: LinkProps) => {
  const { to, children, disabled, onClick, radius, ariaLabel, ...rest } = props;
  const closeActiveDrawer = useCloseActiveDrawer();

  if (disabled) {
    return <span aria-disabled>{children}</span>;
  }

  return (
    <RouterLink
      to={to}
      aria-disabled={disabled}
      aria-label={
        typeof children === "string"
          ? children || ariaLabel
          : ariaLabel || "click link"
      }
      style={{
        pointerEvents: disabled ? "none" : undefined,
        borderRadius: radius,
      }}
      onClick={(e) => {
        closeActiveDrawer();
        onClick?.(e);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          closeActiveDrawer();
          onClick?.(e as any);
        }
      }}
      {...rest}
    >
      {children}
    </RouterLink>
  );
};
