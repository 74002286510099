import { firestore } from "@/firebase/firebase";
import { IAnalyticsEventData } from "@/services/Analytics";
import { addDoc, collection } from "firebase/firestore";
class FirestoreService {
  get db() {
    return firestore;
  }

  async saveEvent({
    event,
    data,
  }: {
    event: string;
    data?: IAnalyticsEventData;
  }) {
    // const eventsListDocument =
    const eventTypeCollection = collection(this.db, "events", "types", event);

    await addDoc(eventTypeCollection, {
      timestamp: Date.now(),
      ...data,
    });
  }
}

export const Firestore = new FirestoreService();
