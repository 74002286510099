import {
  IconBook,
  IconBriefcase,
  IconBuilding,
  IconUserCog,
  IconWorld,
} from "@tabler/icons-react";
import { IAdvizerSearchFilterKeys, IResourceKeys } from "@/types/StoreTypes";

export interface IResourceConfig {
  key: IResourceKeys;
  filtersKey: IAdvizerSearchFilterKeys;
  color: string;
  Icon: typeof IconBriefcase;
  order: number;
}

const baseConfigs = {
  industry: {
    key: "industry" as IResourceKeys,
    filtersKey: "industries" as IAdvizerSearchFilterKeys,
    color: "pink",
    Icon: IconWorld,
    order: 1,
  },
  jobFunction: {
    key: "jobFunction" as IResourceKeys,
    filtersKey: "jobFunctions" as IAdvizerSearchFilterKeys,
    color: "cyan",
    Icon: IconBriefcase,
    order: 2,
  },
  subject: {
    key: "subject" as IResourceKeys,
    filtersKey: "subjects" as IAdvizerSearchFilterKeys,
    color: "grape",
    Icon: IconBook,
    order: 3,
  },
  trait: {
    key: "trait" as IResourceKeys,
    filtersKey: "traits" as IAdvizerSearchFilterKeys,
    color: "orange",
    Icon: IconUserCog,
    order: 4,
  },
  company: {
    key: "company" as IResourceKeys,
    filtersKey: "companies" as IAdvizerSearchFilterKeys,
    color: "green",
    Icon: IconBuilding,
    order: 5,
  },
};

export const ResourceConfigs: Record<
  keyof typeof baseConfigs | IAdvizerSearchFilterKeys,
  IResourceConfig
> = {
  ...baseConfigs,
  industries: baseConfigs.industry,
  jobFunctions: baseConfigs.jobFunction,
  subjects: baseConfigs.subject,
  traits: baseConfigs.trait,
  companies: baseConfigs.company,
};
