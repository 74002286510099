export function isObjectEmpty(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export function parsePersonalBrandingLinks(links: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = links.split(urlRegex);

  return (
    <>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        ) : (
          part
        ),
      )}
    </>
  );
}

export function removeUndefinedFromObject(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined),
  );
}

export function shuffleArray(array: any[]) {
  return [...array].sort(() => Math.random() - 0.5);
}

export function checkIfMatchesEmailFormat({
  email,
  emailFormat,
}: {
  email: string;
  emailFormat: string;
}) {
  if (!emailFormat) return false;
  const regex = new RegExp(emailFormat, "i");
  return regex.test(email.toLowerCase());
}
