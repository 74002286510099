import { useStore } from "@/store";

class NavigationServiceClass {
  navigate(path: string) {
    useStore.getState().navigation.setNavToPath(path);
  }

  openExternalLink(url: string) {
    window.open(url, "_blank");
  }

  navToLogin() {
    this.navigate("/login");
  }
}

export const Navigation = new NavigationServiceClass();
