import { forwardRef } from "react";
import {
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
  Stack,
  Group,
  CloseButton,
} from "@mantine/core";
import { useDrawer } from "@/store/selectors";
import { useIsDesktop, useStyles } from "@/styles/useStyles";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";

export interface DrawerProps
  extends Omit<MantineDrawerProps, "opened" | "onClose"> {
  id: string;
  onClose?: () => void;
  withDoneButton?: boolean;
  ariaLabel?: string;
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props, ref) => {
  const {
    id: drawerId,
    children,
    title,
    withCloseButton = true,
    withDoneButton,
    onClose,
    ariaLabel,
    ...rest
  } = props;
  const { isOpen, closeDrawer } = useDrawer(drawerId);
  const { theme } = useStyles();
  const isDesktop = useIsDesktop();

  return (
    <MantineDrawer
      opened={isOpen}
      withCloseButton={false}
      closeOnClickOutside
      closeOnEscape
      role="dialog"
      aria-modal="true"
      aria-label={ariaLabel || (typeof title === "string" ? title : "Dialog")}
      size={isDesktop ? "lg" : undefined}
      onClose={() => {
        onClose?.();
        closeDrawer();
      }}
      styles={{
        body: {
          height: props.position === "bottom" ? "100%" : "100vh",
          backgroundColor: theme.colors.background[1],
          paddingBottom: 40,
          padding: 0,
        },
      }}
      {...rest}
      ref={ref}
    >
      <Stack h="100%" gap={0}>
        <Stack role="presentation">
          <Group justify="space-between" p="md">
            {typeof title === "string" ? (
              <Text titleStyle="h2" flex={1} c="text.8">
                {title || ""}
              </Text>
            ) : (
              title || <span />
            )}
            {withCloseButton && !withDoneButton ? (
              <CloseButton
                onClick={() => {
                  closeDrawer();
                }}
                size="lg"
                c="text.0"
                bg="background.4"
                radius="xl"
                style={{ zIndex: 1000 }}
                aria-label="Close dialog"
              />
            ) : null}

            {withDoneButton ? (
              <Button
                variant="light"
                color={theme.colors.background[5]}
                radius="xl"
                aria-label="Close dialog"
                onClick={() => {
                  closeDrawer();
                }}
              >
                Done
              </Button>
            ) : null}
          </Group>
        </Stack>
        {children}
      </Stack>
    </MantineDrawer>
  );
});

Drawer.displayName = "Drawer";
