import { Group } from "@mantine/core";
import { Text } from "@/components/ui/Text";
import { IconUserStar } from "@tabler/icons-react";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import { useFilteredAdvizersCount } from "@/store/selectors";
import { useStore } from "@/store";

export const TotalVisibleAdvizersIndicator = () => {
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const filteredAdvizersCount = useFilteredAdvizersCount();
  const activeFilterPartnerId = useStore(
    (state) => state.advizerSearch.activeFilterPartnerId,
  );
  const { theme } = useStyles();

  return (
    <Group gap={8}>
      <IconUserStar
        size={isLaptopOrBigger ? 26 : 18}
        color={theme.colors.primary[9]}
      />
      <Text c="primary.9" size={isLaptopOrBigger ? "xl" : "md"}>
        <span
          style={{ fontWeight: 700 }}
          aria-label={`${filteredAdvizersCount} ${activeFilterPartnerId ? "alums" : "advizers"}`}
          aria-live="polite"
        >
          {filteredAdvizersCount}
        </span>{" "}
        {!activeFilterPartnerId ? (
          <span>{filteredAdvizersCount === 1 ? "Advizer" : "Advizers"}</span>
        ) : (
          <span>{filteredAdvizersCount === 1 ? "Alum" : "Alums"}</span>
        )}
      </Text>
    </Group>
  );
};
