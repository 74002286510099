import { useEffect } from "react";
import { checkIfMatchesEmailFormat } from "@/lib/helpers";
import { useActiveUser, usePartners } from "@/store/selectors";
import { useStore } from "@/store";
import { useParams } from "react-router-dom";

export const Partner = () => {
  const { partnerSlug } = useParams();
  const partners = usePartners();
  const setActivePartner = useStore((state) => state.partner.setActivePartner);
  const setIsAppLoading = useStore((state) => state.setIsAppLoading);
  const activeUser = useActiveUser();

  useEffect(() => {
    if (partnerSlug && partners) {
      const partner = partners?.find((p) => p.slug === partnerSlug);
      setActivePartner(partner);
    }

    setIsAppLoading(false);
  }, [partnerSlug, partners, setActivePartner, setIsAppLoading]);

  useEffect(() => {
    if (partnerSlug) {
      if (partners) {
        const partner = partners.find((p) => p.slug === partnerSlug);
        setActivePartner(partner || null);
      }
      return;
    } else if (!activeUser) {
      setActivePartner(null);
    }

    if (partners && activeUser?.partnerId) {
      const partner = partners?.find((p) => p.id === activeUser?.partnerId);
      setActivePartner(partner || null);
    } else if (partners && activeUser?.email) {
      const partner = partners?.find((p) =>
        checkIfMatchesEmailFormat({
          email: activeUser?.email || "",
          emailFormat: p.emailFormat || "",
        }),
      );

      setActivePartner(partner || null);
    }
  }, [activeUser, activeUser?.email, partners, setActivePartner, partnerSlug]);

  return null;
};
