import { Group, Stack, Space, Box, Avatar } from "@mantine/core";
import { Drawer } from "@/components/ui/Drawer";
import { Button } from "@/components/ui/Button";
import { useActiveUser, useDrawer } from "@/store/selectors";
import { Firebase } from "@/firebase/firebase";
import { Analytics } from "@/services/Analytics";
import { Text } from "@/components/ui/Text";

export const drawerId = "userDrawer";

export function UserDrawer() {
  const user = useActiveUser();
  const { closeDrawer } = useDrawer(drawerId);

  return (
    <Drawer id={drawerId} style={{ zIndex: 1000 }} size="md" position="right">
      <Stack h="100%" justify="space-between" p="md">
        <Stack>
          <Stack gap={0}>
            <Group justify="center">
              <Box
                bg="primary.8"
                p={0}
                bd="1px solid primary.1"
                style={{ borderRadius: "50%" }}
              >
                <Avatar color="secondary.6" radius="xl" size={60} />
              </Box>
            </Group>
            <Space h="xs" />
            {user?.displayName && (
              <Text titleStyle="h2" c="text.9" ta="center">
                {user?.displayName}
              </Text>
            )}
            <Text titleStyle="h3" c="text.7" ta="center">
              {user?.email}
            </Text>

            <Space h="xl" />
            <Group justify="center">
              <Button
                variant="transparent"
                size="sm"
                onClick={() => {
                  closeDrawer();
                  Analytics.logout();
                  Firebase.logout();
                }}
              >
                Log out
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
