import { Select } from "@/components/ui/Select";
import { Analytics } from "@/services/Analytics";
import { useStore } from "@/store";
import {
  useCurrentPartnerHasSpecialQuestions,
  useQuestions,
} from "@/store/selectors";
import { useIsLaptopOrBigger } from "@/styles/useStyles";

export const QuestionSelector = () => {
  const currentPartnerHasSpecialQuestions =
    useCurrentPartnerHasSpecialQuestions();
  const questions = useQuestions();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const activeQuestionId = useStore(
    (state) => state.advizerSearch.activeQuestionId,
  );
  const setActiveQuestionId = useStore(
    (state) => state.advizerSearch.setActiveQuestionId,
  );
  return (
    <Select
      flex={1}
      maw={600}
      bold={currentPartnerHasSpecialQuestions}
      clearable
      placeholder={
        currentPartnerHasSpecialQuestions
          ? "⭐ Click for special questions!"
          : "Click to focus on a question"
      }
      variant={isLaptopOrBigger ? "default" : "default"}
      size={isLaptopOrBigger ? "md" : "sm"}
      radius="xl"
      value={activeQuestionId || null}
      onChange={(value) => {
        if (value) {
          const question = questions.find((q) => q.id === value);
          Analytics.filterAdvizers({
            filterType: "question",
            filterValue: question
              ? `Q${question?.number}: ${question?.title}`
              : value,
            resourceId: value,
          });
        }

        setActiveQuestionId(value || null);
      }}
      data={[
        {
          value: "",
          label: "All Questions",
        },
        ...questions.map((question: any) => ({
          value: question.id,
          label: `${!question.isStandard ? "⭐" : ""} Q${question.number}: ${
            question.title
          }`,
        })),
      ]}
    />
  );
};
