console.log("ENV", import.meta.env);

const envVars = import.meta.env;

const localConfig = {
  apiUrl: "http://localhost:5000",
};

const stagingConfig = {
  apiUrl: "https://advize-staging-api-645456828348.us-central1.run.app",
};

const prodConfig = {
  apiUrl: "https://advize-production-api-980910018979.us-central1.run.app",
};

let configToUse = localConfig;

if (envVars.VITE_ADVIZE_ENV === "prod") {
  configToUse = prodConfig;
} else if (envVars.VITE_ADVIZE_ENV === "staging") {
  configToUse = stagingConfig;
}

const hostname = window.location.hostname;
let clientEnv = "local";

if (hostname.includes("staging") || hostname.includes("netlify")) {
  clientEnv = "staging";
} else if (hostname.includes("advizehub.com")) {
  clientEnv = "prod";
}

export const config = {
  ...configToUse,
  cacheInvalidator: 20, // increase this to force user client to refetch data from server
  clientEnv,
  isEmulate: envVars.VITE_ADVIZE_ENV === "local",
  isLocal: envVars.VITE_ADVIZE_ENV === "local",
  isLocalClient: clientEnv === "local",
  isStaging: envVars.VITE_ADVIZE_ENV === "staging",
  isStagingClient: clientEnv === "staging",
  isProduction:
    envVars.VITE_ADVIZE_ENV === "prod" ||
    envVars.VITE_ADVIZE_ENV === "production",
  isProductionClient: clientEnv === "prod",
  siteUrl: window.location.origin,
  supportEmailLink:
    "mailto:hello@advizehub.com?subject=Support%20Request%20-%20Advize",
  termsUrl: "https://www.advizehub.com/terms-conditions",
  privacyUrl: "https://www.advizehub.com/privacy-policy",
  slackInviteLink:
    "https://join.slack.com/t/advize-talk/shared_invite/zt-2slz1tt2v-yJf7N1Kt04kTmops5H0RYg",
  authRedirectUrl: `${window.location.origin}/auth/redirect`,
  socialLinks: {
    linkedin: "https://www.linkedin.com/company/advizehub/",
    tiktok: "https://www.tiktok.com/@advizehub.com",
    instagram: "https://www.instagram.com/advizehub",
  },
};

console.log({ config });
