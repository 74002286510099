import { useEffect } from "react";
import { Firebase } from "@/firebase/firebase";
import { useActiveUser } from "@/store/selectors";
import { useNavigate } from "react-router-dom";
import { Analytics } from "@/services/Analytics";

export const AuthRedirect = () => {
  const user = useActiveUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    async function init() {
      const user = await Firebase.signInFromLink();
      if (user) {
        console.log("LOGGED IN", { user });
        Analytics.login({
          action: "Email Link - Logged In",
          email: user?.email || "",
        });
      }
    }

    init();
  }, []);

  return null;
};
