import { IVideo } from "@/types/ApiTypes";
import { IAdvizerModel } from "./Advizer";

export class AdvizerVideo {
  declare id: IVideo["id"];
  declare titleRaw: IVideo["title"];
  declare url: IVideo["url"];
  declare question: IVideo["question"];
  declare createdAt: IVideo["createdAt"];
  declare updatedAt: IVideo["updatedAt"];
  advizer: IAdvizerModel;
  questionIdRaw: IVideo["questionId"];

  constructor(apiData: any) {
    const { title, questionId, ...rest } = apiData;
    Object.assign(this, rest);

    this.titleRaw = title;
    this.questionIdRaw = questionId;
    this.advizer = apiData.advizer;
  }

  get title() {
    return `${this.question?.title || this.titleRaw || ""}`;
  }

  get questionId() {
    return this.question?.id || this.questionIdRaw;
  }

  get questionNumber() {
    return this.question?.number;
  }
}

export interface IAdvizerVideo extends AdvizerVideo {}
