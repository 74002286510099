import { Group, Loader, Space, Stack } from "@mantine/core";
import { FixedSizeList } from "react-window";
import { AdvizerVideoCard } from "@/components/AdvizerVideoCard";
import { AdvizerSearchControls } from "@/components/advizerSearchControls/AdvizerSearchControls";
import { useIsLaptopOrBigger, useStyles } from "@/styles/useStyles";
import {
  useActiveUserPartner,
  useCurrentPartnerHasSpecialQuestions,
  useFilteredAdvizers,
  useIsAdvizersFiltered,
  useIsApiDown,
  useQuestions,
} from "@/store/selectors";
import { AdvizerSearchFilters } from "@/components/AdvizerSearchFilters";
import { useCallback, useMemo } from "react";
import { AutoSizer } from "react-virtualized";
import { useStore, useValueFromBreakpoint } from "@/hooks";
import { ErrorOrNoResults } from "@/components/templates/ErrorOrNoResults";
import { IconArrowDown } from "@tabler/icons-react";
import { Title } from "@/components/ui/Title";
import { Text } from "@/components/ui/Text";

// TODO use virtualized list
export const AdvizerSearchPage = () => {
  const { theme, ...styles } = useStyles();
  const isLaptopOrBigger = useIsLaptopOrBigger();
  const isApiDown = useIsApiDown();

  const isInlineAdvizerSearchOpen = useStore(
    (state) => state.advizerSearch.isInlineAdvizerSearchOpen,
  );
  const collapseHeader = useStore(
    (state) => state.advizerSearch.collapseHeader,
  );
  const setCollapseHeader = useStore(
    (state) => state.advizerSearch.setCollapseHeader,
  );
  const { advizers, isAdvizersLoading } = useFilteredAdvizers();
  const isAdvizersFiltered = useIsAdvizersFiltered();
  const partner = useActiveUserPartner();
  const partnerHasSpecialQuestions = useCurrentPartnerHasSpecialQuestions();
  const questions = useQuestions();

  const advizerVideoCardHeight = useValueFromBreakpoint({
    mobile: 680,
    tablet: 720,
    laptop: 450,
    desktop: 500,
  });

  const maxVideoCardWidth = useValueFromBreakpoint({
    mobile: 500,
    tablet: 650,
    laptop: 1050,
    desktop: 1200,
  });

  const showMainBanner = useMemo(
    () => !isAdvizersFiltered,
    [isAdvizersFiltered],
  );

  const renderMainBanner = useCallback(
    ({ style, key }: { style: any; key: string }) => {
      if (!showMainBanner) return null;

      return (
        <Group
          id={"banner"}
          align="start"
          justify="center"
          key={key}
          pt={0}
          w="100%"
          style={{
            ...style,
            overflow: "hidden",
          }}
          gap={0}
        >
          <Stack
            h="100%"
            justify="center"
            align="center"
            ta="center"
            maw={500}
            gap="xs"
            p="xs"
          >
            <Group w="100%" gap={6} justify="center" align="center">
              <Title order={1} c="primary.8" fz={40}>
                Watch More.
              </Title>
              <Title order={1} c="primary.8" fz={40}>
                Worry Less.
              </Title>
            </Group>
            <Title order={2} size="h3" c="text.8" fw={600}>
              Watch {(9000).toLocaleString()}+ short interview videos from{" "}
              {(695).toLocaleString()}+ professionals. Use our fast & easy
              filters to find the best{" "}
              <span style={{ fontWeight: 800 }}>unfiltered</span> career advice
              for you.
            </Title>
            <Space h="xl" />

            {partner && partnerHasSpecialQuestions ? (
              <Stack
                w="100%"
                align="center"
                justify="center"
                maw={440}
                gap="xs"
              >
                <Title order={3} size="h4" c="primary.7">
                  Heads up! Your alums answered special questions
                </Title>
                <Stack
                  w="100%"
                  align="start"
                  justify="center"
                  gap="xs"
                  maw={350}
                  ta="left"
                >
                  {questions
                    .filter((question) => !question.isStandard)
                    .map((question) => (
                      <Title order={4} size="h5" key={question.id} c="text.6">
                        ⭐ Q{question.number}:{" "}
                        {question.title.endsWith("?")
                          ? question.title
                          : question.title + "?"}
                      </Title>
                    ))}
                </Stack>
                <Space h="md" />
              </Stack>
            ) : null}

            <IconArrowDown
              size={32}
              color={theme.colors.primary[7]}
              className="pulse-animation"
            />
          </Stack>
        </Group>
      );
    },
    [
      partner,
      partnerHasSpecialQuestions,
      questions,
      showMainBanner,
      theme.colors.primary,
    ],
  );

  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      if (showMainBanner && index === 0) {
        return renderMainBanner({ style, key: String(index) });
      }

      const advizer = advizers?.[showMainBanner ? index - 1 : index];
      if (!advizer) return null;

      return (
        <Group
          id={String(index)}
          align="start"
          justify="center"
          key={advizer.id}
          pt={0}
          w="100%"
          style={{
            ...style,
            overflow: "hidden",
          }}
          gap={0}
        >
          <AdvizerVideoCard
            advizerId={advizer.id}
            advizer={advizer}
            maxWidth={maxVideoCardWidth}
          />
        </Group>
      );
    },
    [showMainBanner, advizers, maxVideoCardWidth, renderMainBanner],
  );

  const renderFailedApi = useCallback(() => {
    return (
      <Stack mih={400} h="50vh" align="center" justify="center" pt="xl" gap={4}>
        <Title order={1} size="h2" c="text.9">
          Shoot, something went wrong 😖
        </Title>
        <Text c="text.9">
          We're working on it and will be back up as soon as possible!
        </Text>
      </Stack>
    );
  }, []);

  const renderAdvizers = useCallback(() => {
    if (isAdvizersLoading) {
      return (
        <Stack mih={400} h="50vh" align="center" justify="center" pt="xl">
          <Loader color="primary.5" size="xl" />
          <Title order={1} size="h2" c="text.7">
            Loading Advizers...
          </Title>
        </Stack>
      );
    }

    if (!advizers?.length) {
      return <ErrorOrNoResults />;
    }

    // TODO get tallest one in list, just force row height and card height to be same
    return (
      <Stack flex={1} w="100%">
        <AutoSizer>
          {({ width, height }) => (
            <FixedSizeList
              width={width}
              height={height}
              itemCount={advizers?.length + (showMainBanner ? 1 : 0) || 0}
              itemSize={advizerVideoCardHeight}
              onScroll={(props) => {
                if (
                  props.scrollDirection === "forward" &&
                  props.scrollOffset > styles.dimensions.headerHeight
                ) {
                  setCollapseHeader(true);
                } else {
                  setCollapseHeader(false);
                }
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {rowRenderer}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Stack>
    );
  }, [
    advizerVideoCardHeight,
    advizers?.length,
    isAdvizersLoading,
    rowRenderer,
    setCollapseHeader,
    showMainBanner,
    styles.dimensions.headerHeight,
  ]);

  return (
    <Group
      pt={collapseHeader ? 0 : styles.dimensions.headerHeight}
      gap={0}
      align="start"
      h="100vh"
      mah="100vh"
    >
      <Stack p={0} gap={0} flex={2} mah="100vh">
        <AdvizerSearchControls />
        <Stack align="center" w="100%" mih={"100vh"} gap="lg" bg="background.0">
          {isApiDown ? renderFailedApi() : renderAdvizers()}
        </Stack>
      </Stack>
      {isLaptopOrBigger && isInlineAdvizerSearchOpen ? (
        <Stack
          h={`calc(100vh - ${
            collapseHeader ? 0 : styles.dimensions.headerHeight
          }px)`}
          maw={400}
          flex={1}
          pos="sticky"
          top={collapseHeader ? 0 : styles.dimensions.headerHeight - 10}
          style={{ zIndex: 10 }}
          gap={0}
          p={0}
        >
          <AdvizerSearchFilters />
        </Stack>
      ) : null}
    </Group>
  );
};
