import { useEffect } from "react";
import { config } from "@/config";
import { Loader, Stack } from "@mantine/core";
import { Link } from "@/components/ui/Link";
import { Text } from "@/components/ui/Text";
import { Title } from "@/components/ui/Title";

export function RedirectPage() {
  useEffect(() => {
    window.location.href = config.slackInviteLink;
  }, []);

  return (
    <Stack h="100vh" justify="center" align="center">
      <Loader size="xl" color="primary.7" />
      <Title order={1} c="text.6" ta="center">
        Redirecting you to our <br />
        Slack community...
      </Title>
      <Text c="text.4">
        Having issues?{" "}
        <Link to={config.slackInviteLink} target="_blank">
          Click here to contact support.
        </Link>
      </Text>
    </Stack>
  );
}
