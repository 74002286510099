import { Dialog } from "@mantine/core";
import { useDialog } from "@/store/selectors";
import { WelcomeDialog } from "./WelcomeDialog";
import { CookieDialog } from "./CookieDialog";

const dialogs = {
  welcome: WelcomeDialog,
  cookieConsent: CookieDialog,
};

export function DialogContainer() {
  const { activeDialogId, closeActiveDialog } = useDialog();

  const DialogComponent = dialogs[activeDialogId as keyof typeof dialogs];

  return (
    <Dialog
      opened={!!activeDialogId}
      onClose={closeActiveDialog}
      size="lg"
      radius="md"
      px="lg"
      withBorder
      bg="background.2"
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialog-title"
    >
      {DialogComponent && <DialogComponent />}
    </Dialog>
  );
}
