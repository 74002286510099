import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  GoogleAuthProvider,
  signInWithPopup,
  connectAuthEmulator,
} from "firebase/auth";
import { config } from "../config";
import { Notifications } from "../services/Notifications";
import { Analytics } from "../services/Analytics";
import { Experience } from "../services/Experience";
import { RealtimeDatabase } from "../services/RealtimeDatabase";

const productionFirebaseConfig = {
  apiKey: "AIzaSyCPvBuXmyNqp3CgKg6YtD_R9J6FDZqsb8w",
  authDomain: "advize-production.firebaseapp.com",
  projectId: "advize-production",
  storageBucket: "advize-production.appspot.com",
  databaseURL: "https://advize-production-default-rtdb.firebaseio.com/",
  messagingSenderId: "980910018979",
  appId: "1:980910018979:web:f081fc575a594e91a38a16",
  measurementId: "G-2ZNFYLLDPD",
};

const stagingFirebaseConfig = {
  apiKey: "AIzaSyA41pQA67I5FA-eP7EveEIF79kqeluZNP8",
  authDomain: "advize-staging.firebaseapp.com",
  projectId: "advize-staging",
  storageBucket: "advize-staging.firebasestorage.app",
  messagingSenderId: "645456828348",
  appId: "1:645456828348:web:1b93dd618794a6ad41fdb9",
};

// Initialize Firebase
export const app = initializeApp(
  config.isStaging ? stagingFirebaseConfig : productionFirebaseConfig
);
export const auth = getAuth();
export const firestore = getFirestore();
export const db = getDatabase();

if (config.isEmulate) {
  connectFirestoreEmulator(firestore, "localhost", 4003);
  connectAuthEmulator(auth, "http://127.0.0.1:4001");
  connectDatabaseEmulator(db, "localhost", 4004);
}

const actionCodeSettings = {
  url: config.authRedirectUrl,
  handleCodeInApp: true,
  // dynamicLinkDomain: "example.page.link",
};

/***** FUNCTIONS ******/

async function signInWithGoogle() {
  try {
    const provider = new GoogleAuthProvider();

    Analytics.loginAttempt({ action: "Google - Open popup" });

    const result = await signInWithPopup(auth, provider);

    const user = result.user;
    const isNewUser =
      user?.metadata?.creationTime === user.metadata?.lastSignInTime;

    if (isNewUser) {
      Analytics.register({ action: "Google", email: user.email || "" });
      Experience.showConfetti();
      // Experience.showWelcomeDialog();
    } else {
      Analytics.login({ action: "Google", email: user.email || "" });
    }

    return user;
  } catch (error: any) {
    console.error(error);
    Notifications.showNotification({
      title: "Error authenticating with Google",
      message: error?.message || "An error occurred",
      error: true,
    });
  }
}

async function loginWithEmailAndPassword(params: {
  email: string;
  password: string;
  partnerId?: string;
}) {
  let user = null;
  let response = null;
  const { email, password, partnerId } = params;

  try {
    Analytics.loginAttempt({
      action: "Email/Password - Start",
      partnerId,
      email,
    });
    response = await signInWithEmailAndPassword(auth, email, password);
    user = response.user;
    Analytics.login({ action: "Email/Password - Success", partnerId, email });
  } catch (error: any) {
    console.log(error);

    try {
      response = await createUserWithEmailAndPassword(auth, email, password);
      Analytics.register({
        action: "Email/Password",
        email,
        userAdvizeId: response.user.uid,
        partnerId,
      });
      Experience.showConfetti();
      // setTimeout(() => {
      //   Experience.showWelcomeDialog();
      // }, 5000);
    } catch (error: any) {
      console.error(error);
      if (error.message.includes("email-already-in-use")) {
        if (auth.currentUser) {
          try {
            const credential = EmailAuthProvider.credential(email, password);
            response = await linkWithCredential(auth.currentUser, credential);
            Analytics.login({
              action: "Email/Password - Link user creds",
              partnerId,
              email,
            });
          } catch (e) {
            console.error(e);
            Analytics.error({
              action: "Email/Password - Error",
              error: error.message,
              partnerId,
              email,
            });
          }
        }

        Analytics.error({
          action: "Email/Password - Bad credentials",
          error: error.message,
          partnerId,
          email,
        });

        Notifications.showNotification({
          title: "Error logging in",
          message: "Please double check your email and password",
          error: true,
        });

        return {
          error:
            "Error logging in. Please double check your email and password",
        };
      }
    }
  }

  user = user || response?.user;

  console.log("User logged in", { response, user, partnerId });

  if (user && partnerId) {
    RealtimeDatabase.updateUser(user.uid, { partnerId });
  } else {
    Analytics.error({
      action: "Email/Password - Error",
      error: "Failed to add partner ID to user",
      partnerId,
      email,
    });
  }

  return { user };
}

async function sendSignInLink(params: { email: string; partnerId?: string }) {
  const { email, partnerId } = params;
  try {
    Analytics.loginAttempt({
      action: "Email Link - Init send email link",
      partnerId,
      email,
    });
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        Analytics.loginAttempt({
          action: "Email Link - Email sent",
          partnerId,
          email,
        });
        window.localStorage.setItem("emailForSignIn", email);
      })
      .catch((error) => {
        console.error(error);
        Analytics.error({
          action: "Email Link - Error sending email",
          error: error.message,
          partnerId,
          email,
        });
      });
  } catch (error) {
    console.error(error);
  }
}

async function signInFromLink() {
  try {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");

      if (!email) {
        Analytics.loginAttempt({
          action: "Email Link - Prompt for email",
          email: email || "",
        });
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }

      Analytics.loginAttempt({
        action: "Email Link - Handle link",
        email: email || "",
      });

      if (!email) return;

      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );

      window.localStorage.removeItem("emailForSignIn");

      Notifications.showNotification({
        title: "Welcome to Advize!",
        message: "You are now logged in.",
      });

      Analytics.login({ action: "Email Link" });

      return result.user;
    } else {
      Analytics.error({
        action: "Email Link",
        error: "Not a sign in link",
      });
    }
  } catch (error: any) {
    console.error(error);
    Analytics.error({ action: "Email Link", error: error.message });
  }
}

async function logout() {
  await signOut(auth);
  Notifications.showNotification({
    title: "You are now logged out",
  });
}

export const Firebase = {
  loginWithEmailAndPassword,
  sendSignInLink,
  signInFromLink,
  logout,
  signInWithGoogle,
};
