import { useStore } from "@/store";
import { useIsAdvizersFiltered } from "@/store/selectors";
import { useIsMobile } from "@/styles/useStyles";
import { Button } from "@/components/ui/Button";

export const ResetAdvizerSearchButton = () => {
  const isAdvizersFiltered = useIsAdvizersFiltered();
  const resetAdvizerSearch = useStore(
    (state) => state.advizerSearch.resetAdvizerSearch,
  );
  const isMobile = useIsMobile();

  if (!isAdvizersFiltered) return null;

  return (
    <Button
      variant="transparent"
      size={isMobile ? "xs" : "sm"}
      onClick={resetAdvizerSearch}
    >
      Reset
    </Button>
  );
};
