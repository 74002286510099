import { useArrayOfSelectedAdvizerSearchFilters } from "@/store/selectors";
import { useIsLaptopOrBigger } from "@/styles/useStyles";
import { AdvizerTag } from "@/components/AdvizerTag";

export const SelectedSearchTags = () => {
  const arrayOfSelectedAdvizerSearchFilters =
    useArrayOfSelectedAdvizerSearchFilters();
  const isLaptopOrBigger = useIsLaptopOrBigger();

  return (
    <>
      {arrayOfSelectedAdvizerSearchFilters
        .sort((a, b) => {
          return a.title > b.title ? 1 : -1;
        })
        .map((tag) => (
          <AdvizerTag
            key={tag.id}
            tag={tag}
            size={isLaptopOrBigger ? "lg" : "md"}
          />
        ))}
    </>
  );
};
