import { alpha, Box, AspectRatio, Group, Stack, Skeleton } from "@mantine/core";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player/lazy";
import { useStyles } from "@/styles/useStyles";
import {
  IconArrowLeft,
  IconArrowRight,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";
import {
  useIsLoggedIn,
  useIsRestrictedApp,
  useIsVideoPlaying,
} from "@/store/selectors";
import { useStore } from "@/store";
import { useDeviceType } from "@/hooks";
import { Navigation } from "@/services/Navigation";
import { Analytics } from "@/services/Analytics";
import travoltaGif from "@/assets/memes/travolta-confused-meme.gif";
import { Image } from "@/components/ui/Image";
import { ActionIcon } from "@/components/ui/ActionIcon";
import { Text } from "@/components/ui/Text";

export const AdvizerVideo = (props: {
  videoId: string;
  title: string | ReactNode;
  url: string;
  questionNumber?: number;
  isInBackground?: boolean;
  withControls?: boolean;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  video?: any;
}) => {
  const {
    videoId,
    title,
    url,
    questionNumber,
    isInBackground,
    withControls,
    onClickNext,
    onClickPrevious,
    video,
  } = props;
  const { theme } = useStyles();
  const isVideoPlaying = useIsVideoPlaying(videoId);

  const setActiveVideoId = useStore((state) => state.videos.setActiveVideoId);
  const [isPlayHovered, setIsPlayHovered] = useState(false);
  const { isMobileDevice } = useDeviceType();
  const isLoggedIn = useIsLoggedIn();
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const [userSecondsWatched, setUserSecondsWatched] = useState(0);
  const [previousPlayedSeconds, setPreviousPlayedSeconds] = useState(0);
  const lastReportedPercent = useRef(0);
  const [hasSeeked, setHasSeeked] = useState(false);
  const isRestrictedApp = useIsRestrictedApp();
  const incrementNumVideosPlayed = useStore(
    (state) => state.user.incrementNumVideosPlayed,
  );
  const [videoError, setVideoError] = useState(false);

  const { videoTitleFontSize, videoTitleLineClamp } = useMemo(() => {
    const titleLength = String(title).length;

    if (titleLength > 50) {
      return {
        videoTitleFontSize: 16,
        lineClamp: 2,
      };
    }

    return {
      videoTitleFontSize: 20,
      videoTitleLineClamp: 1,
    };
  }, [title]);

  const resetVideo = useCallback(() => {
    setPreviousPlayedSeconds(0);
    setHasSeeked(false);
    setSecondsWatched(0);
    setUserSecondsWatched(0);
    setIsPlayHovered(false);
  }, []);

  useEffect(() => {
    if (isInBackground && isVideoPlaying) {
      setActiveVideoId(null);
    }
  }, [isInBackground]);

  useEffect(() => {
    if (url && !isInBackground) {
      const embedUrl = `https://noembed.com/embed?url=${url}`;

      fetch(embedUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) {
            setVideoError(true);
            Analytics.videoError({ error: data.error, videoId, url });
          } else {
            setVideoError(false);
          }
        });
    }
  }, [url, isInBackground, videoId]);

  const handlePlayVideo = useCallback(() => {
    setActiveVideoId(videoId);

    if (!isLoggedIn) {
      if (isRestrictedApp) {
        Navigation.navToLogin();
        Analytics.click({
          action: "Play Video > Not logged in",
        });
      }
    }
  }, [isLoggedIn, isRestrictedApp, setActiveVideoId, videoId]);

  const handleVideoProgress = useCallback(
    (data: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
    }) => {
      if (!isVideoPlaying) {
        console.log("progress but not playing");
        return;
      }

      const roundedPercent = Math.floor((data.played * 100) / 5) * 5;
      let localHasSeeked = hasSeeked;

      const currentPlayedSeconds = Math.floor(data.playedSeconds);
      const newUserSecondsWatched =
        currentPlayedSeconds > 0 ? userSecondsWatched : 0;

      if (Math.abs(data.playedSeconds - previousPlayedSeconds) > 1.5) {
        setHasSeeked(true);
        localHasSeeked = true;
        Analytics.seekVideo({
          video,
          videoSeekFromSeconds: previousPlayedSeconds,
          videoSeekToSeconds: data.playedSeconds,
          videoDurationSeconds: durationInSeconds,
          videoHasSeeked: hasSeeked,
        });
        setPreviousPlayedSeconds(data.playedSeconds);
        return;
      }

      setPreviousPlayedSeconds(data.playedSeconds);

      if (roundedPercent > lastReportedPercent.current) {
        lastReportedPercent.current = roundedPercent;
        setSecondsWatched(Math.floor(data.playedSeconds));
        Analytics.videoProgress({
          video,
          videoWatchedSeconds: Math.floor(data.playedSeconds),
          videoWatchedPercent: roundedPercent,
          videoDurationSeconds: durationInSeconds,
          videoHasSeeked: localHasSeeked,
        });
      }

      if (newUserSecondsWatched >= 5 && newUserSecondsWatched % 5 === 0) {
        console.log("5 seconds");
        Analytics.videoProgressEvery5Seconds({
          video,
          userSecondsWatched: newUserSecondsWatched,
          videoDurationSeconds: durationInSeconds,
          videoWatchedSeconds: Math.floor(data.playedSeconds),
          videoWatchedPercent: roundedPercent,
          videoHasSeeked: localHasSeeked,
        });
      }

      setUserSecondsWatched((s) => s + 1);
    },
    [
      durationInSeconds,
      hasSeeked,
      isVideoPlaying,
      previousPlayedSeconds,
      userSecondsWatched,
      video,
    ],
  );

  const renderCoreContent = useCallback(() => {
    if (!videoId) {
      return <Skeleton h="100%" w="100%" animate />;
    }

    if (videoError) {
      return (
        <Image
          alt="Travolta confused meme"
          src={travoltaGif}
          fit="scale-down"
          noRatio
          memeText={
            <>
              <Text
                size="xl"
                titleStyle="h2"
                c="white"
                style={{ WebkitTextStroke: "1px black" }}
              >
                So... where's the video?
              </Text>
              <Text
                titleStyle="h3"
                ta="center"
                c="white"
                style={{ WebkitTextStroke: "1px black" }}
                maw={400}
              >
                Our bad! Seems something went wrong and we'll look into it ASAP.
              </Text>
            </>
          }
        />
      );
    }

    return (
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        playing={isVideoPlaying}
        light
        controls
        playsinline={true}
        volume={1}
        muted={!!isMobileDevice}
        fallback={<Box h="100%" w="100%" bg="background.4" />}
        style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
        playIcon={
          <Group
            bg={
              isPlayHovered
                ? alpha(theme.colors.primary[9], 0.9)
                : alpha(theme.colors.primary[9], 0.5)
            }
            p={8}
            style={{ borderRadius: "50%" }}
          >
            <IconPlayerPlayFilled
              color={
                isPlayHovered ? theme.colors.text[0] : theme.colors.text[2]
              }
              size={40}
            />
          </Group>
        }
        onStart={() => {
          resetVideo();
          incrementNumVideosPlayed();
          Analytics.startVideo({
            video,
            videoDurationSeconds: durationInSeconds,
          });
        }}
        onProgress={handleVideoProgress}
        onDuration={(d) => {
          setDurationInSeconds(d);
        }}
        onPlay={() => {
          setActiveVideoId(videoId);
          handlePlayVideo();
        }}
        onError={(error) => {
          console.log("error", error);
        }}
        onPause={() => {
          Analytics.pauseVideo({
            video,
            videoWatchedSeconds: secondsWatched,
            videoWatchedPercent: lastReportedPercent.current,
            videoDurationSeconds: durationInSeconds,
            userSecondsWatched,
          });
        }}
        onClickPreview={() => {
          handlePlayVideo();
        }}
      />
    );
  }, [
    durationInSeconds,
    handlePlayVideo,
    handleVideoProgress,
    incrementNumVideosPlayed,
    isMobileDevice,
    isPlayHovered,
    isVideoPlaying,
    resetVideo,
    secondsWatched,
    setActiveVideoId,
    theme.colors.primary,
    theme.colors.text,
    url,
    userSecondsWatched,
    video,
    videoError,
    videoId,
  ]);

  return (
    <Stack
      gap={0}
      h="100%"
      w="100%"
      justify="flex-start"
      style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
      display={isInBackground ? "none" : "flex"}
    >
      <Group
        bg={alpha(theme.colors.primary[7], 1)}
        px="xs"
        py="xs"
        w="100%"
        justify="space-between"
        gap={4}
      >
        {onClickPrevious ? (
          <NextPrevButton onClick={onClickPrevious} hide={!withControls} />
        ) : null}
        <Text
          titleStyle="h1"
          fz={videoTitleFontSize}
          flex={1}
          c={theme.colors.text[0]}
          ta="left"
          px={withControls ? 4 : 0}
          lineClamp={videoTitleLineClamp}
        >
          {questionNumber && (
            <span style={{ color: theme.colors.primary[3], paddingRight: 8 }}>
              Q{questionNumber}
            </span>
          )}
          {title}
        </Text>
        {onClickNext ? (
          <NextPrevButton onClick={onClickNext} isRight hide={!withControls} />
        ) : null}
      </Group>
      <AspectRatio
        component="div"
        ratio={16 / 9}
        w="100%"
        pos="relative"
        style={{
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
          overflow: "hidden",
        }}
        bg="background.2"
        onMouseEnter={() => {
          setIsPlayHovered(true);
        }}
        onMouseLeave={() => {
          setIsPlayHovered(false);
        }}
      >
        {renderCoreContent()}
      </AspectRatio>
    </Stack>
  );
};

function NextPrevButton(props: {
  onClick: () => void;
  isRight?: boolean;
  hide?: boolean;
}) {
  return (
    <Stack h={42}>
      <ActionIcon
        onClick={props.hide ? undefined : props.onClick}
        size={props.hide ? 10 : "xl"}
        radius="xl"
        color="primary.8"
        opacity={props.hide ? 0 : 1}
        ariaLabel={props.isRight ? "Go to previous video" : "Go to next video"}
      >
        {props.isRight ? <IconArrowRight /> : <IconArrowLeft />}
      </ActionIcon>
    </Stack>
  );
}
