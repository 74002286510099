class AccessibilityClass {
  constructor() {}

  handleOnKeyDown(params: {
    e: React.KeyboardEvent<HTMLDivElement>;
    onClick?: () => void;
  }) {
    const { e, onClick } = params;
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      onClick?.();
    }
  }

  handleTabIndex(params: { tabIndex?: number; onClick?: () => void }) {
    const { tabIndex, onClick } = params;
    return onClick ? tabIndex || 0 : tabIndex;
  }
}

export const Accessibility = new AccessibilityClass();
